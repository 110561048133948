<template>
  <div class="kt-widget17">
    <div class="kt-widget17__stats">
      <div class="kt-widget17__items">
        <div class="kt-widget17__item" @click="goDetail(pendingKey)">
          <span class="kt-widget17__icon">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--warning'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M12.704 14l2.963-4h-2.223V6L9 12h2.222v2H6a1 1 0 01-1-1V3a1 1 0 011-1h12a1 1 0 011 1v10a1 1 0 01-1 1h-5.296z" fill="#000" opacity=".3"/><path d="M9.804 10.914L9 12h2.222v4l4.445-6h-.205l4.745-3.427a.5.5 0 01.793.405V17a2 2 0 01-2 2H5a2 2 0 01-2-2V6.978a.5.5 0 01.793-.405l6.011 4.341z" fill="#000"/></g></svg></span>
          <span class="kt-widget17__subtitle">
            Incompletos
          </span>
          <span class="kt-widget17__desc">
            {{pending}} contratos
          </span>
        </div>
        <div class="kt-widget17__item"  @click="goDetail(completeKey)">
          <span class="kt-widget17__icon">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--success'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M12.934 16.072l6.426-5.116.158-.125a1.5 1.5 0 00.003-2.35l-6.587-5.238a1.5 1.5 0 00-1.868 0L4.475 8.485a1.5 1.5 0 00-.001 2.347l.156.124 6.436 5.117a1.5 1.5 0 001.868 0z" fill="#000" fill-rule="nonzero"/><path d="M11.056 18.67l-5.72-4.547a.903.903 0 00-1.276.154.91.91 0 00.166 1.279l6.861 5.247a1.5 1.5 0 001.823 0l6.858-5.243a.915.915 0 00.167-1.288.91.91 0 00-1.286-.155l-5.725 4.554a1.5 1.5 0 01-1.868 0z" fill="#000" opacity=".3"/></g></svg></span>
          <span class="kt-widget17__subtitle">
            Pagados / Completos
          </span>
          <span class="kt-widget17__desc">
            {{completed}} contratos
          </span>
        </div>
        <div class="kt-widget17__item" @click="$router.push({name: 'contract-select'})">
          <span class="kt-widget17__icon">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--brand'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M5 3h1a1 1 0 011 1v16a1 1 0 01-1 1H5a1 1 0 01-1-1V4a1 1 0 011-1zm5 0h1a1 1 0 011 1v16a1 1 0 01-1 1h-1a1 1 0 01-1-1V4a1 1 0 011-1z" fill="#000"/><rect fill="#000" opacity=".3" transform="rotate(-19 17.826 11.946)" x="16.326" y="2.946" width="3" height="18" rx="1"/></g></svg>
          </span>
          <span class="kt-widget17__subtitle">
            Disponibles
          </span>
          <span class="kt-widget17__desc">
            {{available}} contratos
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import service from "@/service/catalogue.service"
const PENDINGKEY = "Incompleto";
const COMPLETEDKEY = "Pagado";
export default {
  name: "widget-17",
  components: {
  },
  data() {
    return {
      pendingKey:PENDINGKEY,
      completeKey:COMPLETEDKEY,
      available: 0,
      pending: 0,
      completed: 0

    };
  },
  mounted() {
    service.dataDashboard().then( x => {
      const result = x.data ? x.data.data : {};
      this.available = result.disponibles;
      this.pending = result.incompletos;
      this.completed = result.pagados;
    });
  },
  methods: {
    goDetail(param) {
      this.$router.push({name: 'transaction', params:{tab: param}})
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  }
};
</script>
