<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
        <!--begin:: Widgets/Activity-->
        <Widget17></Widget17>
        <!--end:: Widgets/Activity-->
      </div>
    </div>

      <div class="row">


        <div class="col-xl-6 col-lg-6 order-lg-3 order-xl-1">
          <KTPortlet v-bind:title="'Contratos disponibles'">
            <template v-slot:body>
              <Widget5 v-bind:datasrc="contracts"></Widget5>
            </template>
          </KTPortlet>
        </div>
        <div class="col-lg-6 col-xl-6 order-lg-1 order-xl-1">
          <div class="row">
            <KTPortlet v-bind:title="'Información de usuario'">
              <template v-slot:body>
                <userInfo></userInfo>

              </template>
            </KTPortlet>
          </div>
          <div class="row">
            <KTPortlet v-bind:title="'Pasos para obtener tu contrato'">
              <template v-slot:body>
                <!--Begin::Timeline 3 -->
                <Timeline2 v-bind:datasrc="timelines"></Timeline2>
                <!--End::Timeline 3 -->
              </template>
            </KTPortlet>
          </div>
        </div>

      </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import Widget17 from "@/views/partials/widgets/Indications.vue";
import Timeline2 from "@/views/partials/widgets/Timeline2.vue";
import Widget5 from "@/views/partials/widgets/Widget5.vue";
import userInfo from "@/views/partials/widgets/UserInfo.vue";

/**
 * Sample widgets data source
 */
import timelines from "@/common/mock/widget-timeline.json";
import service from "@/service/contract.service"
export default {
  name: "dashboard",
  components: {
    KTPortlet,
    Widget17,
    Timeline2,
    Widget5,
    userInfo
  },
  data() {
    return {
      timelines: timelines,
      tabIndex: 0,
      tabIndex2: 0,
      contracts: []
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Sistema"},{ title: "Inicio" }]);
    service.get().then(x => {
      this.contracts = x.data.data
    })
  },
  methods: {
  }
};
</script>
