import httpService from "../helpers/http.service";

export default {
    getCatalogue(url) {
        return httpService.get(url);
    },
    dataDashboard() {
        return httpService.get('dashboard');

    }
};
