<template>
  <v-app style="height: 300px;">
<!--    <v-row justify="space-around">-->

<!--      <v-avatar color="indigo">-->
<!--        <v-icon dark>mdi-account-circle</v-icon>-->
<!--      </v-avatar>-->
<!--    </v-row>-->

    <v-card
            class="mx-auto"
            color="#26c6da"
            dark
            width="100%"
            v-if="!loading"
    >
      <v-card-title>
        <v-icon
                large
                left
        >
          mdi-email
        </v-icon>
        <span class="title font-weight-light">{{user.email}}</span>
      </v-card-title>

      <v-card-text class="headline">
        <p><strong>Plan adquirido:</strong> {{user.business_info.plan_name}}</p>
        <p><strong>Contratos generados:</strong> {{user.business_info.contratos_generados}}</p>
        <p> <strong>Información de plan:</strong> {{user.business_info.quedan}}</p>
      </v-card-text>

      <v-card-actions>
        <v-list-item class="grow">
          <v-list-item-avatar color="grey darken-3">
            <v-avatar color="grey darken-3" class="elevation-6">
              <v-icon dark>mdi-account-circle</v-icon>
            </v-avatar>

          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{user.firstname + ' ' + user.lastname}}</v-list-item-title>
          </v-list-item-content>

          <v-row
                  align="center"
                  justify="end"
          >
            <b-button variant="outline-light" @click="$router.push('profile')"><i
                    class="fa fa-cog"></i> Editar perfil</b-button>
          </v-row>
        </v-list-item>
      </v-card-actions>
    </v-card>
  </v-app>
</template>

<script>
  import service from "@/service/login.service"

  export default {

  name: "user-info",
  components: {},
  props: {
  },
  data() {
    return {
      user: {},
      loading: false
    }
  },
  created() {
    this.loading = true;
    service.getUserProfile().then(x => {
      this.user = x.data.data;
    }).finally(() => {
      this.loading = false;
    })
  },
  methods: {
  },
  computed: {
  }
};
</script>
