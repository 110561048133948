import httpService from "../helpers/http.service";

export default {

    register(payload, id){
        let  url = 'contratos';
        url += id ? '/' + id : '';
        return httpService.post(url , payload);
    },
    get(id){
        let  url = 'contratos';
        url += id ? '/' + id : '';
        return httpService.get(url);
    },
    getSteps(id){
        let  url = `contratos/${id}/pasos`;
        return httpService.get(url);
    },
    getFirstStep(id){
        let  url = `contratos/${id}/inicial`;
        return httpService.get(url);
    },
    getNexStep(payload){
        let  url = 'paso/siguiente';
        return httpService.post(url , payload);
    },
    delete(id){
        let  url = 'contratos/'  + id ;
        return httpService.delete(url);
    },
    registerTransaction(payload){

        let  url = 'transacciones';
        return httpService.post(url , payload);
    }
};
