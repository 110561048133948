<template>
  <div class="kt-widget5">
    <perfect-scrollbar style="max-height: 100vh; position: relative;">
      <template v-for="(item, i) in datasrc">
        <div class="kt-widget5__item" :key="`item-${i}`">
          <div class="kt-widget5__content">
            <div class="kt-widget5__section">
              <a href="#" class="kt-widget5__title">
                {{ item.nombre }}
              </a>
              <p class="kt-widget5__desc">
                {{ item.descripcion }}
              </p>
              <div class="kt-widget5__info" v-html="htmlInfo(item.pasos, item.precio)"></div>
            </div>
          </div>
          <div class="kt-widget5__pic">
            <v-card :color="item.color" dark>
              <v-card-actions>
                <v-list-item class="grow">
                  <v-row color="grey darken-3">
                    <v-btn outlined @click="newTransaction(item)">Usar
                      <v-icon class="mr-1">arrow_right_alt</v-icon></v-btn>
                  </v-row>
                </v-list-item>

              </v-card-actions>
            </v-card>

          </div>

        </div>
      </template>
    </perfect-scrollbar>
  </div>
</template>

<script>
export default {
  name: "widget-5",
  components: {},
  props: {
    datasrc: Array
  },
  created() {
  },
  methods: {
    htmlComp(precio) {
        return "<div class=\"kt-widget5__stats\"><span class=\"kt-widget5__number\"> $" + precio+ "</span>\n\t\t\t\t\t\t\t</div>"
    },
    htmlInfo(pasos, precio) {
      return "<span>Precio:</span>\t<span class=\"kt-font-info\">$" + precio +  "</span>"
    },
    newTransaction(item) {
      this.$router.replace({ name: 'transaction-register', params : {id: item.id}});
    },
  }
};
</script>
